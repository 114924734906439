import { createWebHashHistory, createRouter, createWebHistory } from "vue-router";

// Views

import Home from "@/views/Home.vue";
import FeaturesTemplate from "@/views/FeaturesTemplate.vue";
import About from "@/views/About.vue";
import Contact from "@/views/Contact.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/features",
    name: "FeaturesTemplate",
    component: FeaturesTemplate,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
