<template>
  <div class="content-wrapper">
    <div class="">
      <!-- <TopBarWhiteBackground /> -->
      <v-container>
        <div class="top-space pt-10">
          <h1 class="mb-4">Welcome to blach blah</h1>
          <Markdown :source="`i am a ~~tast~~ **test**. :) :(`" />
          <p class="mb-4 mt-4">
            This is a demo website for OneClinic. OneClinic is a cloud-based,
            comprehensive, and easy-to-use clinic management software. It is
            designed to help doctors and clinic staff manage their clinic
            efficiently and effectively. It is a one-stop solution for all your
            clinic management needs.
          </p>
          <h2 class="mb-4">Purpose</h2>
          <p class="mb-6">
            The purpose of this website is to demonstrate the features of
            OneClinic. You can try out the features by clicking on the "Try Us
            Out" button below. You can also contact us for more information.
          </p>
          <!-- <v-btn class="request-btn" color="primary" @click="$router.push('/contact')">Request Demo</v-btn> -->
        </div>
        <v-row gutters="16">
          <v-col cols="12" sm="6" md="4" lg="4">
            <v-card class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">Library</div>
                  <div class="text-caption">
                    Greyhound divisely hello coldly fonwderfully
                  </div>
                </div>
              </v-card-item>
              <v-card-actions>
                <v-btn> Button </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4">
            <v-card class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">Headline</div>
                  <div class="text-caption">
                    Greyhound divisely hello coldly fonwderfully
                  </div>
                </div>
              </v-card-item>
              <v-card-actions>
                <v-btn> Button </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4">
            <v-card class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">Headline</div>
                  <div class="text-caption">
                    Greyhound divisely hello coldly fonwderfully
                  </div>
                </div>
              </v-card-item>
              <v-card-actions>
                <v-btn> Button </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!--///-->
        <v-row gutters="16">
          <v-col cols="12" sm="6" md="4" lg="4">
            <v-card class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">Library</div>
                  <div class="text-caption">
                    Greyhound divisely hello coldly fonwderfully
                  </div>
                </div>
              </v-card-item>
              <v-card-actions>
                <v-btn> Button </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4">
            <v-card class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">Headline</div>
                  <div class="text-caption">
                    Greyhound divisely hello coldly fonwderfully
                  </div>
                </div>
              </v-card-item>
              <v-card-actions>
                <v-btn> Button </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4">
            <v-card class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">Headline</div>
                  <div class="text-caption">
                    Greyhound divisely hello coldly fonwderfully
                  </div>
                </div>
              </v-card-item>
              <v-card-actions>
                <v-btn elevated> Read </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!--/// Banner End Here ///-->
    <v-container>
      <div class="pt-5">
        <v-timeline align="start" side="end">
          <v-timeline-item
            v-for="(year, i) in years"
            :key="i"
            :dot-color="year.color"
            size="small"
          >
            <template v-slot:opposite>
              <div
                :class="`pt-1 headline font-weight-bold text-${year.color}`"
                v-text="year.year"
              ></div>
            </template>
            <div>
              <h2
                :class="`mt-n1 headline font-weight-light mb-3 text-${year.color}`"
              >
                Lorem ipsum
              </h2>
              <div>
                Lorem ipsum dolor sit amet, no nam oblique veritus. Commune
                scaevola imperdiet nec ut, sed euismod convenire principes at.
                Est et nobis iisque percipit, an vim zril disputando
                voluptatibus, vix an salutandi sententiae.
              </div>
              <div class="mt-3 d-flex align-center">
                <v-rating :model-value="4" readonly />
                <div>
                  <v-btn class="mr-2" :color="year.color" variant="outlined">
                    Detail
                  </v-btn>
                  <v-btn :color="year.color" variant="outlined">
                    Check
                  </v-btn>
                </div>
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </div>
    </v-container>
    <v-container>
      <v-row gutters="16">
          <v-col cols="12" sm="6" md="4" lg="4">
            <v-card class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">Library</div>
                  <div class="text-caption">
                    Greyhound divisely hello coldly fonwderfully
                  </div>
                </div>
              </v-card-item>
              <v-card-actions>
                <v-btn> Button </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4">
            <v-card class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">Headline</div>
                  <div class="text-caption">
                    Greyhound divisely hello coldly fonwderfully
                  </div>
                </div>
              </v-card-item>
              <v-card-actions>
                <v-btn> Button </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4">
            <v-card class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">Headline</div>
                  <div class="text-caption">
                    Greyhound divisely hello coldly fonwderfully
                  </div>
                </div>
              </v-card-item>
              <v-card-actions>
                <v-btn> Button </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
// import { useRoute, useRouter } from "vue-router";
// import TopBarWhiteBackground from "@/components/TopBarWhiteBackground.vue";
// import FooterView from "@/components/Footer.vue";
// import VueMarkdown from 'vue-markdown'
import Markdown from "vue3-markdown-it";
import axios from "axios";
export default {
  components: {
    // TopBarWhiteBackground,
    // FooterView,
    // VueMarkdown
    Markdown,
  },
  setup() {},
  name: "Home",
  methods: {
    async submitForm() {
      try {
        let fd = JSON.parse(JSON.stringify(this.formdata));
        console.log(fd);
        axios
          .post(
            "https://jkp4c7sryk7a4avtalwplid2km0crwoz.lambda-url.us-east-1.on.aws/",
            fd
          )
          .then((response) => {
            // console.log(response);
            // reset form
            this.formdata = {
              name: "",
              email: "",
              phone: "",
              designation: "",
              clinic: "",
            };
          })
          .catch((error) => {
            console.log(error);
          });
        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
  },
  data: () => ({
    tab: null,
    years: [
      {
        color: "cyan",
        year: "1960",
      },
      {
        color: "green",
        year: "1970",
      },
      {
        color: "pink",
        year: "1980",
      },
      {
        color: "amber",
        year: "1990",
      },
      {
        color: "orange",
        year: "2000",
      },
    ],
    formdata: {
      name: "",
      email: "",
      phone: "",
      designation: "",
      clinic: "",
      message: "Contact from One Clinic Website",
      subject: "Contact from One Clinic Website",
    },
  }),
};
</script>